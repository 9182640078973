.btn
{
    font-size: 14px;
    font-weight: bold;

    padding: 5px 23px;

    transition: all .3s;

    border: 2px solid $btn-border-color;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0 1px 2px rgba($btn-box-shadow-color,.1);

    @include text_headline();

    &.btn-sm
    {
        font-size: 12px;
        padding: 4px 23px;
    }

    &[disabled]
    {
        cursor: not-allowed;

        opacity: .3;
    }

    &:hover
    {
        box-shadow: 0 0 5px rgba($btn-box-shadow-color,.3);
    }

    &.cancel
    {
        border-color: $btn-cancel-border-color;
        background-color: $btn-cancel-background-color;
        @include text_headline($btn-cancel-font-color);
    }

    &.authorize
    {
        line-height: 1;

        display: inline;

        color: $btn-authorize-font-color;
        border-color: $btn-authorize-border-color;
        background-color: $btn-authorize-background-color;

        span
        {
            float: left;

            padding: 4px 20px 0 0;
        }

        svg
        {
            fill: $btn-authorize-svg-fill-color;
        }
    }

    &.execute
    {
        background-color: $btn-execute-background-color-alt;
        color: $btn-execute-font-color;
        border-color: $btn-execute-border-color;
    }
}

.btn-group
{
    display: flex;

    padding: 30px;

    .btn
    {
        flex: 1;

        &:first-child
        {
            border-radius: 4px 0 0 4px;
        }

        &:last-child
        {
            border-radius: 0 4px 4px 0;
        }
    }
}

.authorization__btn
{
    padding: 0 10px;

    border: none;
    background: none;

    &.locked
    {
        opacity: 1;
    }

    &.unlocked
    {
        opacity: .4;
    }
}

.expand-methods,
.expand-operation
{
    border: none;
    background: none;

    svg
    {
        width: 20px;
        height: 20px;
    }
}

.expand-methods
{
    padding: 0 10px;

    &:hover
    {
        svg
        {
            fill: $expand-methods-svg-fill-color-hover;
        }
    }

    svg
    {
        transition: all .3s;

        fill: $expand-methods-svg-fill-color;
    }
}


button
{
    cursor: pointer;
    outline: none;

    &.invalid
    {
        @include invalidFormElement();
    }
}
